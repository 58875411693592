<template>
  <v-container fluid>
    <v-col
      v-if="loading"
      cols="12">
      <div class="text-center">
        <v-progress-circular
          color="primary"
          indeterminate/>
      </div>
    </v-col>
    <v-data-table
      v-else
      :headers="headers"
      :items="recalls"
      :items-per-page-options="rowsPerPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="elevation-1"
    >
      <template
        slot="item"
        slot-scope="props">
        <tr>
          <td>{{ props.item | fullId("R") }}</td>
          <td>{{ props.item.name }}</td>
          <td>
            <template v-if="props.item.product">
              {{ props.item.product.fullname }}
            </template>
            <template v-else>
              Kein Produkt ausgewählt
            </template>
          </td>
          <td>{{ props.item.status | recallStatus }}</td>
          <td>{{ props.item.time | daytime }}</td>
          <td>{{ props.item.created_at | datetime }}</td>
          <td>
            <router-link :to="'/appointments/recalls/' + props.item.id">
              <v-btn
                color="primary"
                dark
                small>
                <v-icon>search</v-icon>
              </v-btn>
            </router-link>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import AppointmentsAPI from '@/api/appointments'

export default {
  data() {
    return {
      loading: false,
      sortBy: 'created_at',
      sortDesc: true,
      headers: [
        {
          text: 'Id',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Name',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Produkt',
          sortable: true,
          value: 'product',
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Zeitpunkt',
          sortable: true,
          value: 'time',
        },
        {
          text: 'Erstellt am',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Aktionen',
          value: 'name',
        },
      ],
      rowsPerPage: [25, 50, { text: 'Alle', value: -1 }],
      recalls: [],
    }
  },
  created() {
    this.loadRecalls()
  },
  methods: {
    loadRecalls() {
      this.loading = true
      AppointmentsAPI.getRecalls().then((response) => {
        this.recalls = response
        this.loading = false
      })
    },
  },
}
</script>
