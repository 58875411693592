import base from '@/api/base'

export default {
  getRecalls() {
    return base.get('/me/backend/appointments/recalls')
  },
  getRecall(id) {
    return base.get(`/me/backend/appointments/recalls/${id}`)
  },
  updateRecall(id, status) {
    return base.post(`/me/backend/appointments/recalls/${id}`, { status })
  },
  getConsultingAppointments() {
    return base.get('/me/backend/appointments/consultings')
  },
  getConsultingAppointment(id) {
    return base.get(`/me/backend/appointments/consultings/${id}`)
  },
  updateConsultingAppointment(id, status, date, product_model, note, branch_office_id) {
    return base.post(`/me/backend/appointments/consultings/${id}`, {
      status, date, product_model, note, branch_office_id,
    })
  },
}
